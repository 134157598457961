
.payment-section{
    display: flex;
    padding-top: 50px;
    border-left: 10px solid rgb(94,98,217);
}

.section1{
    width: 70vw;
    height: 100vh;
}
.section2{
    width: 30vw;
    height: 100vh;
}


.pay-mark-logo{
    width: 150px;
}


.kyc-head{
    margin-top: 50px;
    border-bottom: 3px solid rgb(94,98,217);
    display: inline-block;
    padding-bottom: 20px;
}


.termsandconditons{
    border-bottom: 3px solid lightgray;
}

.bottom-section{
    display: flex;
    flex-direction: column;
    margin-top: 240px;
}




.card-new{
    box-shadow: 0 0 5px rgba(0,0,0,.05), 2px 2px 5px rgba(0,0,0,.1);
    background-color: white;
    position: absolute;
    top: 150px;
    right: 260px;
    width: 500px;
    height: 400px;
}


.pre-form-error-text{
    color: red;
    font-size: 12px;
}


.card-new-heading{
    margin-left: 20px;
    margin-top: 20px;
    border-bottom: 3px solid rgb(94,98,217);
    display: inline-block;
    padding-bottom: 15px;
}


.pre-form{
    margin-top: 20px;
}

.pre-form-section{
    display: flex;
    justify-content: space-between;


}

.pay-button{
    background-color: rgb(94,98,217);
    height: 60px;;
    width: 100%

}



.pay-button-section{
    background-color: #f5f6f7;
    height: 60px;
    margin-top: 10px;
}


.section2-img{
    position: absolute;
    top: 0;
    right: 0;
    height: 110vh;
    width: 30vw;
    z-index: -20;
}


.section2-img2{
    width: 200px;
    margin-top: 25px;
    margin-left: 30px;
}




@media only screen and (max-width: 700px) {
   .card-new{
       top:220px;
       right:50px;
       width: 19rem;
   }


   .bottom-section{
    margin-top: 500px;
   }
  }