.left-side {
    background-color: #6865f0;
    position: fixed;
    height: 100%;
    width: 255px;
    margin: 0;
    left: 0;
    z-index: 10;
    /* overflow: scroll; */
}
/* .left-side::-webkit-scrollbar {
    display: none;
} */

.circle-indicator {
    width: 10px;
    height: 10px;
    border-radius: 50px;
}
.payment-btns {
    display: flex;
    gap: 3rem;
    margin: 1rem 0 0 0.5em;
    /* cursor: pointer; */
}
.payment-btns div label {
    font-weight: 600;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 0.9rem;
}
.payment-btns div label,
.payment-btns div input {
    cursor: pointer;
}
.payment-btns div {
    display: flex;
    gap: 0.8rem;
    align-items: center;
    cursor: pointer;
}
.Recent-transaction {
    position: relative;
    min-width: 130px;
    width: 70%;
    max-width: 330px;
}

.Recent-transaction .h5 {
    gap: 1rem;
    background: #6865f0;
    padding: 0.7rem 2rem;
    border-radius: 30px;
    margin-top: 50px;
    font-size: 20px;
    text-align: center;
    z-index: 5;

    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.circle {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background-color: #6865f0;
    display: inline-block;
    margin-top: 18px;
    position: relative;
    left: -40px;
}

.pointer {
    /* height: 20px;
    width: 20px;
    background: #6865f0;
    margin: 0 auto;
    transform: rotate(45deg); */
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;

    border-top: 20px solid #6865f0;
    /* margin-top: -22px; */
    position: absolute;
    left: 45%;
    bottom: -18%;
    /* position: relative;
    left: calc(0.5vw - 150px); */
}
.user-ref-account span {
    text-transform: uppercase;
}
.Insurance-Wrapper {
    display: flex;
    margin: 2rem 5rem;
    gap: 2rem;
}
.form__group {
    position: relative;
    padding: 15px 0 0;
    margin-top: 10px;
    width: 100%;
}

.form__field {
    font-family: inherit;
    width: 70%;
    border: 0;
    /* border-bottom: 2px solid gray; */
    outline: 0;
    font-size: 1rem;
    color: black;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
}

.scrollVertical {
    height: 400px;
    overflow-y: scroll;
}

.rahul {
    border: 1px solid black;
}

.dashboard-right-back {
    position: relative;
    left: 253px;
    width: calc(100%-253px);
    background-color: #f8f8f8;
}

.right-back {
    position: relative;
    left: 253px;
    width: 83.3%;
}
.custom-col-9 {
    flex: 0 0 auto;
    width: 79%;
    overflow: hidden;
}
a {
    color: #ffffff !important;
}

.main-row {
    height: 100vh;
    width: 100%;
    margin: 0;
}

.logo {
    /* background: white; */
    width: fit-content;
    padding: 0 4px;
    /* padding-bottom: ; */
    margin: auto;
    margin-top: 30px;
}

.logo span {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 1px;
    color: #012970;
    font-family: "Rubik", sans-serif;
    margin-top: 3px;
}

.user-avatar-div {
    display: flex;
    justify-content: center;
    padding: 24px;
    padding-bottom: 14px;
}

.user-avatar-div img {
    height: 100px;
    width: 100px;
    object-fit: cover;
    display: flex;
    margin: auto;
    border-radius: 50px;
}

.user-name {
    margin: 0;
    text-align: center;
    font-family: "Rubik";
    color: #ffffff;
    font-size: 20px;
    font-weight: 500;
}

.user-city {
    text-align: center;
    font-family: "Rubik";
    color: #ffffff;
    margin: 0;
}

.nav-list {
    list-style-type: none;
    padding: 0px;
    max-width: 220px;
    margin: auto;
    font-family: "Rubik";
    font-weight: 400;
    text-align: center;
}

.nav-list li {
    /* background: #FFFFFF; */
    border-radius: 14px;
    color: #ffffff;
    padding: 5px 20px;
    font-size: 16px;
    text-align: left;
}

.nav-list li a {
    /* color: #6865F0 !important; */
    text-decoration: none;
}

.selected-menuitem {
    background-color: #ffffff !important;
    color: #6865f0 !important;
}

.selected-menuitem a {
    color: #6865f0 !important;
}

.menu-icon {
    width: 20px;
    height: 20px;
}

.head-dashboard {
    color: #5b5b5a;
    font-family: "Rubik";
    font-weight: 600;
    font-size: 30px;
}

.head-hi {
    color: #52525d;
    margin: 0;
    font-family: "Rubik";
    font-size: 19px;
    margin-left: 5px;
    font-weight: 400;
}
.marquee {
    width: 100%;
    max-width: 650px;
    color: white;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
}
.marquee p {
    display: inline-block;
    padding-left: 100%;
    font-size: 1.5rem;
    animation: marquee 26s linear infinite;
}
@keyframes marquee {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-100%, 0);
    }
}
.blc-section {
    background: #ffff;
    border-radius: 40px;
    padding: 20px;
    margin: 10px 0px;
    z-index: 5;
}

.your-blc {
    color: #a0a2ae;
    font-family: "Rubik";
    font-size: 20px;
    letter-spacing: 0.8px;
    margin: 0;
}

.blc-rs {
    color: #252523;
    font-size: 40px;
    font-weight: 500;
    font-family: "Rubik";
    margin: 0;
}

/* Right back */
.right-back {
    background: #e5e5e55e;
    padding: 0;
}

/* Header Row */
.header-row {
    background: #ffffff;
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
}

.header-sub-row {
    display: flex;
    align-items: center;
}

.header-icon {
    height: 20px;
    width: 20px;
    margin: 6px;
}

.header-blc {
    margin: 0;
    font-family: "Rubik";
    font-size: 24px;
    font-weight: 500;
    color: #252523;
}

/* Deposit section */
.deposit-head {
    font-size: 40px;
    font-family: "Rubik";
    font-weight: 600;
    color: #5b5b5a;
    text-align: center;
    margin-top: 25px;
    max-width: 300px;
}

.total-wallet-box-main {
    max-width: 500px;
    margin: 0px auto;
}

.total-wallet-box-sub {
    background: linear-gradient(110.27deg, #e354ce -12.73%, #6865f0 106.61%);
    box-shadow: 0px 4px 100px rgb(206 95 216 / 40%);
    border-radius: 50px;
    padding: 5px 0;
    display: flex;
    justify-content: center;
    text-align: center;
}

.total-blc-p {
    color: #ffffff;
    font-family: "Rubik";
    font-size: 25px;
    font-weight: 500;
}

.total-blc-rs {
    color: #ffffff;
    font-family: "Rubik";
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 1px;
}

.add-blc-ip {
    width: 100%;
    border: 1px solid #6865f0;
    border-radius: 20px;
    height: 50px;
    padding: 20px;
    text-indent: 20px;
    font-size: 20px;
    font-weight: 400;
    color: #6865f0;
    letter-spacing: 0.8px;
    outline: none;
    font-family: sans-serif;
}
.add-blc-ip::-webkit-outer-spin-button,
.add-blc-ip::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.add-blc-ip[type="number"] {
    -moz-appearance: textfield;
}

.add-blc-notes {
    width: 100%;
    border: 1px solid #6865f0;
    border-radius: 20px;
    text-indent: 49px;
    font-size: 20px;
    font-weight: 400;
    color: #6865f0;
    letter-spacing: 0.8px;
    margin-top: 10px;
    outline: none;
    resize: none;
}

.ip-inr {
    color: #6865f0;
    font-family: "Rubik";
    font-size: 30px;
    font-weight: 400;
    position: relative;
    left: 20px;
    top: 50px;
}

.add-btn-div {
    display: flex;
    margin-top: 25px;
}

.add-btn {
    background: #6865f0;
    border-radius: 20px;
    border: none;
    color: #ffffff;
    width: 220px;
    font-family: "Rubik";
    font-size: 20px;
    margin: auto;
    height: 50px;
}

/* Pay bills */
.contactType-btns {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1.3rem;
    /* line-height: 22px; */
    letter-spacing: 0.02em;
    margin: 1rem;
}
.contactType-btns button {
    border-radius: 16px;
    box-sizing: border-box;
    padding: 0.5rem 1rem;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.02em;
    border: 4px solid #ffffff;
}
.contactbtnActive {
    background-color: #6865f0;
    color: white;
}
.contactbtninActive {
    background-color: white;
    color: black;
}
.pay-bills-grp1 {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.pay-bills-head {
    font-size: 40px;
    font-family: "Rubik";
    font-weight: 600;
    color: #5b5b5a;
    margin-top: 25px;
    width: 500px;
}

.see-billers-btn {
    background: #6865f0;
    border-radius: 20px;
    border: none;
    color: #ffffff;
    font-family: "Rubik";
    font-size: 20px;
    margin: auto;
    height: 50px;
    width: 200px;
}

.pay-bills-center {
    max-width: 400px;
    margin: 30px auto;
}

.pay-bills-main-img {
    width: -webkit-fill-available;
}

.add-your-p {
    color: #252523;
    font-family: "Rubik";
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0.2px;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 2px;
}

.add-more-p {
    color: #52525d;
    text-align: center;
    font-family: "Rubik";
    font-size: 18px;
    letter-spacing: 0.2px;
}

.add-biller-btn {
    background: #6865f0;
    border-radius: 20px;
    border: none;
    color: #ffffff;
    width: 80%;
    font-family: "Rubik";
    font-size: 20px;
    margin: auto;
    height: 50px;
}

.form-main {
    width: 74%;
    margin: 50px auto 0px auto;
    padding: 32px;
    background: #ffffff;
}

.form-sub {
    max-width: 436px;
    margin: auto;
}

.pay-bill-ip {
    width: 100%;
    border: 1px solid #6865f0d9;
    border-radius: 20px;
    height: 50px;
    padding: 20px;
    text-indent: 10px;
    font-size: 20px;
    font-weight: 600;
    color: #221faa;
    letter-spacing: 0.2px;
    margin: 8px 0px;
    outline: none;
}

.grid1-main {
    width: 95%;
    margin: 4rem 0px 0px 1rem;
    padding: 12px;
    background: #ffffff;
}

.payee-col {
    text-align: center;
    margin-top: 5px;
    height: 270px;
}

.payee-col-sub {
    /* width: 12rem; */
    background: #f8f8f8;
    border: 1px solid #6865f0;
    border-radius: 20px;
    padding: 2rem 2rem 0 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    height: 14rem;
    position: relative;
}
.del-btn {
    display: none;
    position: absolute;
    top: 10px;
    z-index: 100;
    border-radius: 10px;
    right: 10px;
    border: none;
    color: white;
    background-color: red;
}
.custom-sub:hover .del-btn {
    display: block;
}

.ac-details {
    align-self: center;
    justify-self: flex-end;
    align-items: end;
    display: grid;
    /* margin-top: 1rem; */
    gap: 0rem;
}
.first-letter {
    align-self: center;
    justify-self: center;
    background: #dc3545;
    color: white;
    width: 45px;
    height: 45px;
    font-size: 25px;
    text-align: center;
    margin: auto;
    padding: 10px;
    border-radius: 30px;
    margin: 0;
}
.propic-user {
    border-radius: 50%;
    width: 100px;
    height: 100px;

    background-color: none;
}

.ac-name {
    letter-spacing: 0.01em;
    color: #252523;
    font-size: 15px;
    text-transform: none;
    max-lines: 1;
    /* height: 50px; */
    font-family: "Rubik";
    /* margin-top: 20px; */
    margin: 0;
    /* overflow: hidden; */
    text-overflow: ellipsis;
}

.ac-number {
    /* margin: 1rem 0; */
    color: #52525d;
    font-size: 14px;
    font-family: "Rubik";
}

.error-text {
    color: red;
    text-align: center;
    width: 100%;
}

.c-modal-pay-bills {
    max-width: 480px !important;
    text-align: center;
}
.c-modal-pay-bills_1 {
    max-width: 580px !important;
    text-align: center;
}

.pay-ip-div {
    max-width: 180px;
    margin: auto;
}

.m-name {
    padding: 20px;
}

.m-name-payee {
    color: #252523;
    font-family: "Rubik";
    font-size: 30px;
    letter-spacing: 0.4px;
    margin: 0;
}

.m-mobile-payee {
    color: #252523;
    font-family: "Rubik";
    font-size: 20px;
    letter-spacing: 0.4px;
    font-weight: 500;
}

.pay-ip-div-e1 {
    font-size: 20px;
    width: 100%;
    border-radius: 20px;
    height: 50px;
    padding: 20px;
    text-indent: 10px;
    letter-spacing: 0.2px;
    margin: 8px 0px;
    outline: none;
    border: none;
    background: rgba(82, 82, 93, 0.07);
}
.pay-otp {
    margin-left: 3px;
    height: 60px;
    margin-top: 30px;
}

.pay-ip-div-e2 {
    font-size: 20px;
    width: 100%;
    border-radius: 20px;
    height: 90px;
    padding: 20px;
    text-indent: 10px;
    letter-spacing: 0.2px;
    margin: 8px 0px;
    outline: none;
    border: none;
    background: rgba(82, 82, 93, 0.07);
}

.arrow-btn-payee {
    background: #6865f0;
    padding: 10px 8px;
    border-radius: 40px;
    height: 40px;
    margin: 20px;
    cursor: pointer;
}

/* Transaction */
.total-transaction-box-main {
    width: 90%;
    margin: 24px 20px;
    padding: 30px;
    padding-top: 0;
    background: #ffffff;
    border-radius: 20px;
    text-align: center;
}

.total-transaction-box-main table {
    border-collapse: inherit;
    border-spacing: 0 10px;
}

.transaction-head {
    font-size: 32px;
    font-family: "Rubik";
    font-weight: 600;
    color: #5b5b5a;
    text-align: center;
    margin-top: 20px;
    max-width: 260px;
}

.date-export-divs {
    width: 86%;
    margin: 20px;
    justify-content: space-between;
}

.date-ips {
    margin: 4px;
    background: #ffffff;
    border: 1px solid #6865f0;
    border-radius: 10px;
    color: #6865f0;
    padding: 5px;
    font-size: 18px;
    font-family: "Rubik";
    text-indent: 5px;
    max-width: 184px;
}

.export-div {
    align-self: center;
    color: #6865f0;
    font-size: 16px;
    cursor: pointer;
    font-family: "Rubik";
    font-weight: 500;
    border: 1px solid #6865f0;
    margin: 0;
    height: fit-content;
    padding: 2px 10px;
    border-radius: 10px;
}

.trans-row th {
    font-family: "Rubik";
    font-weight: 500;
    font-size: 16px;
}

.trans-row1 {
    color: #52525d;
    font-size: 15px;
    font-family: "Rubik";
    cursor: pointer;
}

.pending-span {
    background: yellow;
    color: #5b5b5a;
    padding: 6px 10px;
    border-radius: 15px;
    font-size: 14px;
}

.completed-span {
    background: #1fb770;
    color: white;
    padding: 6px 10px;
    border-radius: 15px;
    font-size: 14px;
}

.failed-span {
    background: red;
    color: white;
    padding: 6px 10px;
    border-radius: 15px;
    font-size: 14px;
}

.reversed-span {
    background: orange;
    color: white;
    padding: 6px 10px;
    border-radius: 15px;
    font-size: 14px;
}

::-webkit-calendar-picker-indicator {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="rgb(104, 101, 240)" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
}

.go-btn {
    background: #6865f0;
    color: white;
    padding: 4px 10px;
    font-size: 16px;
    font-family: "Rubik";
    border-radius: 10px;
    border: none;
    margin-left: 0.5rem;
}
.dates-ips {
    display: flex;
}
.searchwrapper {
    position: relative;
}
.searchicons {
    position: absolute;
    top: 10px;
    z-index: 20;
    left: 15px;
}
.search {
    position: relative;
    font-size: 1rem;
    width: 15rem;
    height: 39px;
    padding-bottom: 3px;
    margin: 4px;
    border-radius: 10px;
    border: 1px solid #6865f0;
    text-indent: 2rem;
}
.filter-checkboxs {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
    margin-left: 4.6rem;
}
.filter-checkboxs h3 {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    /* identical to box height, or 130% */
    align-self: center;
    letter-spacing: 0.02em;

    color: #252523;
}
.filter-checkbox {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}
.filter-checkbox input {
    width: 18px;
    height: 18px;
    color: #6865f0;
    border: 1px solid #6865f0;
    box-sizing: border-box;
    border-radius: 4px;
}

.filter-checkbox label {
    font-family: "Rubik";
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 31px;
    /* or 156% */

    letter-spacing: 0.02em;

    color: #52525d;
}
/* Account Profile */
.account-head {
    font-size: 32px;
    font-family: "Rubik";
    font-weight: 600;
    color: #5b5b5a;
    text-align: center;
    margin-top: 40px;
}

.account-box-main {
    width: 90%;
    margin: 24px auto;
    padding: 5px;
    /* background: #FFFFFF; */
    border-radius: 20px;
    text-align: center;
}

.account-box-main1 {
    background: #ffffff;
    padding: 10px 24px;
    border-radius: 24px 24px 0px 0px;
}

.profile-changep-div {
    width: fit-content;
}

.profile-password-selected {
    border: 0;
    background: none;
    color: #6865f0;
    font-size: 20px;
    font-family: "Rubik";
    font-weight: 500;
    letter-spacing: 0.4px;
    border-bottom: 4px solid #6865f0;
    padding: 0 8px;
}

.profile-password-not-selected {
    border: 0;
    background: none;
    color: #52525d;
    font-size: 20px;
    font-family: "Rubik";
    letter-spacing: 0.4px;
    padding: 0 8px;
}

.account-box-main2 {
    background: #ffffff;
    padding: 10px 24px;
    margin-top: 6px;
}

.user-name-account {
    margin: 0;
    text-align: center;
    font-family: "Rubik";
    color: #252523;
    font-size: 20px;
    font-weight: 500;
}

.user-ref-account {
    margin: 0;
    text-align: center;
    font-family: "Rubik";
    color: #252523;
    font-size: 17px;
    font-weight: 400;
}

.user-details-ips {
    max-width: 420px;
    margin: auto;
    text-align: start;
    margin-top: -30px;
}
.user-details-ips1 {
    max-width: 420px;
    margin: auto;
    text-align: start;
}
.ip-inr-main-profile1 {
    margin-top: 5px;
}
.deduction {
    font-weight: bold;
    color: red;
}
.commission p {
    text-align: center;
    margin-top: 1rem;
    /* font-size: 1.1rem; */
    font-family: sans-serif;
}
.ip-email-p {
    color: #6865f0;
    font-family: "Rubik";
    font-size: 42px;
    font-weight: 400;
    position: relative;
    left: 30px;
    top: 32px;
}

.email-ip-p {
    width: 100%;
    border: 1px solid #6865f0;
    border-radius: 20px;
    height: 40px;
    text-indent: 70px;
    font-size: 20px;
    font-weight: 400;
    color: #252523;
    letter-spacing: 0.8px;
}

.email-ip-p1 {
    width: 100%;
    border: 1px solid #6865f0;
    border-radius: 20px;
    height: 40px;
    font-size: 20px;
    font-weight: 400;
    color: #252523;
    letter-spacing: 0.8px;
    text-indent: 10px;
}
.email-ta-p1 {
    width: 100%;
    border: 1px solid #6865f0;
    border-radius: 20px;
    font-size: 20px;
    font-weight: 400;
    color: #252523;
    letter-spacing: 0.8px;
    text-indent: 10px;
}
.ip-mobile-p {
    color: #6865f0;
    font-family: "Rubik";
    font-size: 42px;
    font-weight: 400;
    position: relative;
    left: 30px;
    top: 34px;
}

.account-box-main3 {
    background: #ffffff;
    padding: 10px 24px;
    margin-top: 6px;
    padding-top: 68px;
}

.ip-password-img {
    color: #6865f0;
    font-family: "Rubik";
    font-size: 42px;
    font-weight: 400;
    position: relative;
    left: 30px;
    top: 40px;
}

.password-ip-p {
    width: 100%;
    border: 1px solid #6865f0;
    border-radius: 20px;
    height: 50px;
    text-indent: 70px;
    font-size: 20px;
    font-weight: 400;
    color: #252523;
    letter-spacing: 0.8px;
}

/* Wallet Transfers */
.walletgrid {
    display: grid;
    grid-template-columns: auto auto auto auto;
    padding: 3rem;
}
.wallet-member {
    position: relative;
    width: 12rem;
}
.search-payers {
    border: 1px solid #6865f0;
    max-width: 440px;
    width: 100%;
    border-radius: 15px;
    color: #52525d;
    font-family: "Rubik";
    font-size: 1rem;
    height: 3rem;
    text-indent: 17px;
}

.mar-top-50 {
    margin-top: 50px;
}

.pay-w-bills-center {
    max-width: 400px;
    margin: 7vh auto;
}

.search-user-btn {
    border: none;
    border-radius: 20px;
    height: 50px;
    font-size: 18px;
    font-weight: 400;
    background: #6865f0;
    letter-spacing: 0.2px;
    margin: 8px 0px;
    outline: none;
    color: white;
    margin-left: 10px;
}

.add-user-dropdown {
    width: 100%;
    border: 1px solid #6865f0d9;
    border-radius: 20px;
    height: 50px !important;
    text-indent: 10px;
    font-size: 20px;
    font-weight: 400;
    color: #6865f0;
    letter-spacing: 0.2px;
    margin: 8px 0px;
    outline: none;
}

.t-details-div {
    padding: 15px;
    border: 3px solid rgba(0, 0, 0, 0.17);
    margin: 15px;
    font-family: "Rubik";
    text-align: left;
    font-weight: 400;
}

.pay-bill-ip1 {
    width: 100%;
    border: 1px solid #6865f0d9;
    border-radius: 20px;
    height: 50px;
    padding: 20px;
    text-indent: 10px;
    font-size: 16px;
    font-weight: 400;
    color: #6865f0;
    letter-spacing: 0.2px;
    margin: 8px 0px;
    outline: none;
}

.titel {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    color: #5b5b5a;
}

.label {
    font-size: 20px;
    font-weight: 600;
    padding: 8px;
}

.resend-btn {
    padding: 4px;
    display: flex;
    margin: auto;
    background: #6865f0;
    color: #ffffff;
}

.pay-url {
    text-align: end;
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-family: "Rubik";
    color: #212121;
}

.ip-inr58 {
    color: #6865f0;
    font-family: "Rubik";
    font-size: 25px;
    font-weight: 400;
    position: relative;
    left: 20px;
    top: 45.5px;
}

.local-search-biller-list {
    width: 85%;
    margin: auto;
}
.delete-payout-btn {
    display: none;
}

.wallet-member:hover .del-btn {
    display: block;
}

.payee-col-sub:hover .delete-payout-btn {
    display: block;
    padding: 0;
    position: absolute;
    right: 4px;
    top: 4px;
    font-size: 14px;
}

.upload-img-png {
    margin: auto;
    width: 112px;
    image-rendering: -webkit-optimize-contrast;
    padding: 5px;
    cursor: pointer;
}

.doc-link-blue {
    color: blue !important;
}
.commissionValue {
    width: fit-content;
    height: fit-content;
}
.commissionValue::-webkit-outer-spin-button,
.commissionValue::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.commissionValue[type="number"] {
    -moz-appearance: textfield;
}
.referral-modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}
.referral-modal-content {
    display: grid;
    justify-items: center;
    gap: 1rem;
    opacity: 1;
    max-width: 700px;
    width: 100%;
    padding: 2rem 0;
    background: #ffffff;
    border-radius: 14px;
    position: relative;
}

.referral-modal-content label {
    font-size: 25px;
}
.referral-modal-content input {
    width: 50%;
    height: 3rem;
}
.referral-modal-content input::-webkit-outer-spin-button,
.referral-modal-content input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.referral-modal-content input[type="number"] {
    -moz-appearance: textfield;
}
.referral-modal-content button {
    border: none;
    background-color: #012970;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    font-size: 1.3rem;
}
.custom-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: 4px;
    outline: none;
    padding: 20px;
}
.success-modal-custom {
    display: grid;
    justify-items: center;
    align-items: center;
    gap: 0.5rem;
    opacity: 1;
    max-width: 900px;
    /* min-width: 400px; */
    /* width: 100%; */
    /* padding: 2rem 0; */
    background: #ffffff;
    position: relative;
    padding: 1rem;
}
.success-modal-custom img {
    margin-bottom: 0.5rem;
}
.success-modal-custom h3 {
    margin: 0;
    font-family: sans-serif;
    padding: 0;
}
.success-modal-custom p {
    color: grey;
}
.success-modal-custom button {
    background: #6865f0;
    border-radius: 20px;
    border: none;
    color: #ffffff;
    font-family: "Rubik";
    padding: 0.5rem 1rem;
}
.uploaded-doc-4 {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
}
.uploaded-doc-4 button {
    border: none;
    /* text-decoration: underline; */
    background-color: inherit;
    border-bottom: 1px solid white;
}
.uploaded-doc-4 button:hover {
    border-bottom: 1px solid black;
}
/* Modal Backdrop */
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Modal Content */
.modal-content-iframe {
    padding: 20px;
    border-radius: 8px;
    display: grid;
    align-items: center;
    background-color: white;
    width: 100%;
    height: 100%;
    max-width: 60rem;
    max-height: 40rem;
    justify-items: center;
    position: relative;
}

/* Close Button */
.close-btn-iframe {
    position: absolute;
    top: 10px;
    right: 20px;
    /* font-size: 1rem; */
    cursor: pointer;
    font-size: 28px;
    color: #333;
}
.merry .modal-body {
    display: flex !important;
    background-color: transparent !important;
    align-items: center !important;
    justify-content: center !important;
}
