@import url(https://fonts.googleapis.com/css2?family=josefin+Sans:wght@400;500;600;700&display=swap);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
.Pg_charges_table table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    border: 1px solid black;
    text-align: center;
}

.Pg_charges_table table td,
.Pg_charges_table table th {
    border: 1px solid black;
    padding: 0.3rem;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
 */
.dummy-success {
    display: grid;
    align-items: center;
    justify-items: center;
    height: 100vh;
}
.pay-bills-grp1 input::-webkit-outer-spin-button,
.pay-bills-grp1 input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.pay-bills-grp1 input {
    -moz-appearance: textfield;
}
.Pg_charges {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    margin-top: 1rem;
}
.Pg_charges h3 {
    font-size: 1rem;
    color: red;
}
.all_charges {
    text-transform: uppercase;
}

.signup_left {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background: url(./assets/bg.png) bottom left no-repeat; */
    background-color: #6865f0;
    background-size: 100%;
    justify-content: center;
    min-height: 400px;
    padding: 40px 10px 20px 10px;
    overflow: hidden;
    position: relative;
}

.signup_left::after {
    content: "";
    position: absolute;
    background-color: rgb(255 249 249 / 9%);
    background-size: 100%;
    height: 100px;
    width: 100px;
    left: -30px;
    border-radius: 50%;
    bottom: -30px;
}

.signup_left::before {
    content: "";
    position: absolute;
    background-color: rgb(255 249 249 / 9%);
    background-size: 100%;
    height: 40px;
    width: 40px;
    transform: rotate(50deg);
    top: 50px;
    right: 100px;
}

.signup_container {
    height: 350px;
    margin: 0px;
    padding: 0px;
}

.signup_title {
    font-size: 25px;
    font-weight: normal;
    margin-bottom: 10px;
}

.signup_left p {
    font-size: 11px;
    text-align: center;
    letter-spacing: 1px;
    margin: 5px;
}

.loginButton {
    width: 120px;
    height: 35px;
    border-radius: 20px;
    background: transparent;
    color: white;
    border: 2px solid white;
    margin: 20px;
    padding: 0px 0px;
}

.modal-body {
    padding: 0px !important;
}

.modal-content {
    border: none !important;
}

.modal-lg,
.modal-xl {
    width: 80% !important;
}

.signup_right {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 10px 20px 10px;
}

.signup_right h1 {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 30px;
    color: #6865f0;
}

.wrapper_buttons {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.wrapper_buttons button {
    color: #c4c4c4;
    border: 0px solid #6865f0;
    padding: 5px 2px;
    margin: 10px;
    width: 100px;
    height: 35px;
    border-radius: 10px;
    background: transparent;
    font-size: 13px;
}

.active_button {
    border: 1px solid #6865f0 !important;
    color: #6865f0 !important;
    font-weight: 500 !important;
}

.business {
    color: #c4c4c4;
    border: 0px solid #6865f0;
    padding: 5px 2px;
    margin: 10px;
    width: 100px;
    height: 35px;
    border-radius: 20px;
    background: transparent;
    font-size: 14px;
    font-weight: 400;
}

.inputWrapper {
    padding: 5px 10px;
    height: 40px;
    background-color: #f2f2ff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    margin: 5px;
}

.inputWrapper input {
    border: none;
    background: transparent;
    font-size: 12px;
    font-weight: 300;
    margin-left: 10px;
    color: #52525d;
    font-weight: 500;
}

.inputWrapper input:focus,
.form-control:focus {
    border-color: inherit;
    box-shadow: none;
    background: transparent;
}

.inputWrapper img {
    width: 12px;
    object-fit: cover;
    margin: 2px 10px;
}

.getOTP {
    width: 120px;
    height: 35px;
    border-radius: 20px;
    background-color: #6865f0;
    color: white;
    border: 0px solid white;
    margin: 20px;
    padding: 0px 0px;
    font-weight: 500;
}

.update-password {
    border-radius: 20px;
    background-color: #6865f0;
    color: white;
    border: 0px solid white;
    margin: 20px;
    padding: 8px;
    font-weight: 500;
}

.referral,
.forgetpassword {
    font-size: 12px;
    color: #6865f0;
    font-weight: 500;
    margin: 5px;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
}
.forgetpassword {
    justify-content: space-between;
}
.forgetpassword button {
    font-size: 12px;
    color: #6865f0;
    font-weight: 500;
    background-color: inherit;
    border: none;
}
.terms {
    font-size: 10px;
    color: #52525d;
    font-weight: 400;
    margin: 20px 5px 5px 5px;
    display: flex;
    justify-content: space-between;
    align-items: top;
}

.text-danger {
    font-size: 11px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 10px;
}

:disabled {
    opacity: 0.5;
}

.aboutus-items{
    width: 350px;
    height: 400px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
}


.aboutus-item-icons{
    margin-bottom: 20px;
}


.heading{
    font-family: Rubik;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.02em;
}

.aboutus-heading{
    margin-top: 40vh;
    width:600px

}


.section2-aboutus{
    z-index: 10;
}




.bg-image{
    position: absolute;
   height: 900px;
   width: 1000px;
   top:0;
   right: 0;
    /* z-index: -1; */
}


.main-image{
    position: absolute;
    bottom:0;
    height: 550px;
    right: 100px;
    /* width: 100%; */
    z-index: 1;
}

.circle-infront{
    position: absolute;
    bottom:150px;
    height: 70px;
    right: 150px;
    z-index:2;
}



.circle-behind{
    position: absolute;
    bottom:350px;
    height: 100px;
    right: 600px;
    z-index:0;
}


.pattern-behind{
    position: absolute;
    bottom:420px;
    height: 100px;
    right: 180px;
    z-index:0;
}



.card-title{
    font-family: Rubik;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 10px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #6865F0;
    margin: 20px 0;
}

.card-des{
    font-family: Rubik;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.04em;
    text-align: left;

}

.left-side {
    background-color: #6865f0;
    position: fixed;
    height: 100%;
    width: 255px;
    margin: 0;
    left: 0;
    z-index: 10;
    /* overflow: scroll; */
}
/* .left-side::-webkit-scrollbar {
    display: none;
} */

.circle-indicator {
    width: 10px;
    height: 10px;
    border-radius: 50px;
}
.payment-btns {
    display: flex;
    grid-gap: 3rem;
    gap: 3rem;
    margin: 1rem 0 0 0.5em;
    /* cursor: pointer; */
}
.payment-btns div label {
    font-weight: 600;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 0.9rem;
}
.payment-btns div label,
.payment-btns div input {
    cursor: pointer;
}
.payment-btns div {
    display: flex;
    grid-gap: 0.8rem;
    gap: 0.8rem;
    align-items: center;
    cursor: pointer;
}
.Recent-transaction {
    position: relative;
    min-width: 130px;
    width: 70%;
    max-width: 330px;
}

.Recent-transaction .h5 {
    grid-gap: 1rem;
    gap: 1rem;
    background: #6865f0;
    padding: 0.7rem 2rem;
    border-radius: 30px;
    margin-top: 50px;
    font-size: 20px;
    text-align: center;
    z-index: 5;

    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.circle {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background-color: #6865f0;
    display: inline-block;
    margin-top: 18px;
    position: relative;
    left: -40px;
}

.pointer {
    /* height: 20px;
    width: 20px;
    background: #6865f0;
    margin: 0 auto;
    transform: rotate(45deg); */
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;

    border-top: 20px solid #6865f0;
    /* margin-top: -22px; */
    position: absolute;
    left: 45%;
    bottom: -18%;
    /* position: relative;
    left: calc(0.5vw - 150px); */
}
.user-ref-account span {
    text-transform: uppercase;
}
.Insurance-Wrapper {
    display: flex;
    margin: 2rem 5rem;
    grid-gap: 2rem;
    gap: 2rem;
}
.form__group {
    position: relative;
    padding: 15px 0 0;
    margin-top: 10px;
    width: 100%;
}

.form__field {
    font-family: inherit;
    width: 70%;
    border: 0;
    /* border-bottom: 2px solid gray; */
    outline: 0;
    font-size: 1rem;
    color: black;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
}

.scrollVertical {
    height: 400px;
    overflow-y: scroll;
}

.rahul {
    border: 1px solid black;
}

.dashboard-right-back {
    position: relative;
    left: 253px;
    width: calc(100%-253px);
    background-color: #f8f8f8;
}

.right-back {
    position: relative;
    left: 253px;
    width: 83.3%;
}
.custom-col-9 {
    flex: 0 0 auto;
    width: 79%;
    overflow: hidden;
}
a {
    color: #ffffff !important;
}

.main-row {
    height: 100vh;
    width: 100%;
    margin: 0;
}

.logo {
    /* background: white; */
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0 4px;
    /* padding-bottom: ; */
    margin: auto;
    margin-top: 30px;
}

.logo span {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 1px;
    color: #012970;
    font-family: "Rubik", sans-serif;
    margin-top: 3px;
}

.user-avatar-div {
    display: flex;
    justify-content: center;
    padding: 24px;
    padding-bottom: 14px;
}

.user-avatar-div img {
    height: 100px;
    width: 100px;
    object-fit: cover;
    display: flex;
    margin: auto;
    border-radius: 50px;
}

.user-name {
    margin: 0;
    text-align: center;
    font-family: "Rubik";
    color: #ffffff;
    font-size: 20px;
    font-weight: 500;
}

.user-city {
    text-align: center;
    font-family: "Rubik";
    color: #ffffff;
    margin: 0;
}

.nav-list {
    list-style-type: none;
    padding: 0px;
    max-width: 220px;
    margin: auto;
    font-family: "Rubik";
    font-weight: 400;
    text-align: center;
}

.nav-list li {
    /* background: #FFFFFF; */
    border-radius: 14px;
    color: #ffffff;
    padding: 5px 20px;
    font-size: 16px;
    text-align: left;
}

.nav-list li a {
    /* color: #6865F0 !important; */
    text-decoration: none;
}

.selected-menuitem {
    background-color: #ffffff !important;
    color: #6865f0 !important;
}

.selected-menuitem a {
    color: #6865f0 !important;
}

.menu-icon {
    width: 20px;
    height: 20px;
}

.head-dashboard {
    color: #5b5b5a;
    font-family: "Rubik";
    font-weight: 600;
    font-size: 30px;
}

.head-hi {
    color: #52525d;
    margin: 0;
    font-family: "Rubik";
    font-size: 19px;
    margin-left: 5px;
    font-weight: 400;
}
.marquee {
    width: 100%;
    max-width: 650px;
    color: white;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
}
.marquee p {
    display: inline-block;
    padding-left: 100%;
    font-size: 1.5rem;
    animation: marquee 26s linear infinite;
}
@keyframes marquee {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-100%, 0);
    }
}
.blc-section {
    background: #ffff;
    border-radius: 40px;
    padding: 20px;
    margin: 10px 0px;
    z-index: 5;
}

.your-blc {
    color: #a0a2ae;
    font-family: "Rubik";
    font-size: 20px;
    letter-spacing: 0.8px;
    margin: 0;
}

.blc-rs {
    color: #252523;
    font-size: 40px;
    font-weight: 500;
    font-family: "Rubik";
    margin: 0;
}

/* Right back */
.right-back {
    background: #e5e5e55e;
    padding: 0;
}

/* Header Row */
.header-row {
    background: #ffffff;
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
}

.header-sub-row {
    display: flex;
    align-items: center;
}

.header-icon {
    height: 20px;
    width: 20px;
    margin: 6px;
}

.header-blc {
    margin: 0;
    font-family: "Rubik";
    font-size: 24px;
    font-weight: 500;
    color: #252523;
}

/* Deposit section */
.deposit-head {
    font-size: 40px;
    font-family: "Rubik";
    font-weight: 600;
    color: #5b5b5a;
    text-align: center;
    margin-top: 25px;
    max-width: 300px;
}

.total-wallet-box-main {
    max-width: 500px;
    margin: 0px auto;
}

.total-wallet-box-sub {
    background: linear-gradient(110.27deg, #e354ce -12.73%, #6865f0 106.61%);
    box-shadow: 0px 4px 100px rgb(206 95 216 / 40%);
    border-radius: 50px;
    padding: 5px 0;
    display: flex;
    justify-content: center;
    text-align: center;
}

.total-blc-p {
    color: #ffffff;
    font-family: "Rubik";
    font-size: 25px;
    font-weight: 500;
}

.total-blc-rs {
    color: #ffffff;
    font-family: "Rubik";
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 1px;
}

.add-blc-ip {
    width: 100%;
    border: 1px solid #6865f0;
    border-radius: 20px;
    height: 50px;
    padding: 20px;
    text-indent: 20px;
    font-size: 20px;
    font-weight: 400;
    color: #6865f0;
    letter-spacing: 0.8px;
    outline: none;
    font-family: sans-serif;
}
.add-blc-ip::-webkit-outer-spin-button,
.add-blc-ip::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.add-blc-ip[type="number"] {
    -moz-appearance: textfield;
}

.add-blc-notes {
    width: 100%;
    border: 1px solid #6865f0;
    border-radius: 20px;
    text-indent: 49px;
    font-size: 20px;
    font-weight: 400;
    color: #6865f0;
    letter-spacing: 0.8px;
    margin-top: 10px;
    outline: none;
    resize: none;
}

.ip-inr {
    color: #6865f0;
    font-family: "Rubik";
    font-size: 30px;
    font-weight: 400;
    position: relative;
    left: 20px;
    top: 50px;
}

.add-btn-div {
    display: flex;
    margin-top: 25px;
}

.add-btn {
    background: #6865f0;
    border-radius: 20px;
    border: none;
    color: #ffffff;
    width: 220px;
    font-family: "Rubik";
    font-size: 20px;
    margin: auto;
    height: 50px;
}

/* Pay bills */
.contactType-btns {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1.3rem;
    /* line-height: 22px; */
    letter-spacing: 0.02em;
    margin: 1rem;
}
.contactType-btns button {
    border-radius: 16px;
    box-sizing: border-box;
    padding: 0.5rem 1rem;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.02em;
    border: 4px solid #ffffff;
}
.contactbtnActive {
    background-color: #6865f0;
    color: white;
}
.contactbtninActive {
    background-color: white;
    color: black;
}
.pay-bills-grp1 {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.pay-bills-head {
    font-size: 40px;
    font-family: "Rubik";
    font-weight: 600;
    color: #5b5b5a;
    margin-top: 25px;
    width: 500px;
}

.see-billers-btn {
    background: #6865f0;
    border-radius: 20px;
    border: none;
    color: #ffffff;
    font-family: "Rubik";
    font-size: 20px;
    margin: auto;
    height: 50px;
    width: 200px;
}

.pay-bills-center {
    max-width: 400px;
    margin: 30px auto;
}

.pay-bills-main-img {
    width: -webkit-fill-available;
}

.add-your-p {
    color: #252523;
    font-family: "Rubik";
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0.2px;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 2px;
}

.add-more-p {
    color: #52525d;
    text-align: center;
    font-family: "Rubik";
    font-size: 18px;
    letter-spacing: 0.2px;
}

.add-biller-btn {
    background: #6865f0;
    border-radius: 20px;
    border: none;
    color: #ffffff;
    width: 80%;
    font-family: "Rubik";
    font-size: 20px;
    margin: auto;
    height: 50px;
}

.form-main {
    width: 74%;
    margin: 50px auto 0px auto;
    padding: 32px;
    background: #ffffff;
}

.form-sub {
    max-width: 436px;
    margin: auto;
}

.pay-bill-ip {
    width: 100%;
    border: 1px solid #6865f0d9;
    border-radius: 20px;
    height: 50px;
    padding: 20px;
    text-indent: 10px;
    font-size: 20px;
    font-weight: 600;
    color: #221faa;
    letter-spacing: 0.2px;
    margin: 8px 0px;
    outline: none;
}

.grid1-main {
    width: 95%;
    margin: 4rem 0px 0px 1rem;
    padding: 12px;
    background: #ffffff;
}

.payee-col {
    text-align: center;
    margin-top: 5px;
    height: 270px;
}

.payee-col-sub {
    /* width: 12rem; */
    background: #f8f8f8;
    border: 1px solid #6865f0;
    border-radius: 20px;
    padding: 2rem 2rem 0 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    height: 14rem;
    position: relative;
}
.del-btn {
    display: none;
    position: absolute;
    top: 10px;
    z-index: 100;
    border-radius: 10px;
    right: 10px;
    border: none;
    color: white;
    background-color: red;
}
.custom-sub:hover .del-btn {
    display: block;
}

.ac-details {
    align-self: center;
    justify-self: flex-end;
    align-items: end;
    display: grid;
    /* margin-top: 1rem; */
    grid-gap: 0rem;
    gap: 0rem;
}
.first-letter {
    align-self: center;
    justify-self: center;
    background: #dc3545;
    color: white;
    width: 45px;
    height: 45px;
    font-size: 25px;
    text-align: center;
    margin: auto;
    padding: 10px;
    border-radius: 30px;
    margin: 0;
}
.propic-user {
    border-radius: 50%;
    width: 100px;
    height: 100px;

    background-color: none;
}

.ac-name {
    letter-spacing: 0.01em;
    color: #252523;
    font-size: 15px;
    text-transform: none;
    max-lines: 1;
    /* height: 50px; */
    font-family: "Rubik";
    /* margin-top: 20px; */
    margin: 0;
    /* overflow: hidden; */
    text-overflow: ellipsis;
}

.ac-number {
    /* margin: 1rem 0; */
    color: #52525d;
    font-size: 14px;
    font-family: "Rubik";
}

.error-text {
    color: red;
    text-align: center;
    width: 100%;
}

.c-modal-pay-bills {
    max-width: 480px !important;
    text-align: center;
}
.c-modal-pay-bills_1 {
    max-width: 580px !important;
    text-align: center;
}

.pay-ip-div {
    max-width: 180px;
    margin: auto;
}

.m-name {
    padding: 20px;
}

.m-name-payee {
    color: #252523;
    font-family: "Rubik";
    font-size: 30px;
    letter-spacing: 0.4px;
    margin: 0;
}

.m-mobile-payee {
    color: #252523;
    font-family: "Rubik";
    font-size: 20px;
    letter-spacing: 0.4px;
    font-weight: 500;
}

.pay-ip-div-e1 {
    font-size: 20px;
    width: 100%;
    border-radius: 20px;
    height: 50px;
    padding: 20px;
    text-indent: 10px;
    letter-spacing: 0.2px;
    margin: 8px 0px;
    outline: none;
    border: none;
    background: rgba(82, 82, 93, 0.07);
}
.pay-otp {
    margin-left: 3px;
    height: 60px;
    margin-top: 30px;
}

.pay-ip-div-e2 {
    font-size: 20px;
    width: 100%;
    border-radius: 20px;
    height: 90px;
    padding: 20px;
    text-indent: 10px;
    letter-spacing: 0.2px;
    margin: 8px 0px;
    outline: none;
    border: none;
    background: rgba(82, 82, 93, 0.07);
}

.arrow-btn-payee {
    background: #6865f0;
    padding: 10px 8px;
    border-radius: 40px;
    height: 40px;
    margin: 20px;
    cursor: pointer;
}

/* Transaction */
.total-transaction-box-main {
    width: 90%;
    margin: 24px 20px;
    padding: 30px;
    padding-top: 0;
    background: #ffffff;
    border-radius: 20px;
    text-align: center;
}

.total-transaction-box-main table {
    border-collapse: inherit;
    border-spacing: 0 10px;
}

.transaction-head {
    font-size: 32px;
    font-family: "Rubik";
    font-weight: 600;
    color: #5b5b5a;
    text-align: center;
    margin-top: 20px;
    max-width: 260px;
}

.date-export-divs {
    width: 86%;
    margin: 20px;
    justify-content: space-between;
}

.date-ips {
    margin: 4px;
    background: #ffffff;
    border: 1px solid #6865f0;
    border-radius: 10px;
    color: #6865f0;
    padding: 5px;
    font-size: 18px;
    font-family: "Rubik";
    text-indent: 5px;
    max-width: 184px;
}

.export-div {
    align-self: center;
    color: #6865f0;
    font-size: 16px;
    cursor: pointer;
    font-family: "Rubik";
    font-weight: 500;
    border: 1px solid #6865f0;
    margin: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 2px 10px;
    border-radius: 10px;
}

.trans-row th {
    font-family: "Rubik";
    font-weight: 500;
    font-size: 16px;
}

.trans-row1 {
    color: #52525d;
    font-size: 15px;
    font-family: "Rubik";
    cursor: pointer;
}

.pending-span {
    background: yellow;
    color: #5b5b5a;
    padding: 6px 10px;
    border-radius: 15px;
    font-size: 14px;
}

.completed-span {
    background: #1fb770;
    color: white;
    padding: 6px 10px;
    border-radius: 15px;
    font-size: 14px;
}

.failed-span {
    background: red;
    color: white;
    padding: 6px 10px;
    border-radius: 15px;
    font-size: 14px;
}

.reversed-span {
    background: orange;
    color: white;
    padding: 6px 10px;
    border-radius: 15px;
    font-size: 14px;
}

::-webkit-calendar-picker-indicator {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="rgb(104, 101, 240)" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
}

.go-btn {
    background: #6865f0;
    color: white;
    padding: 4px 10px;
    font-size: 16px;
    font-family: "Rubik";
    border-radius: 10px;
    border: none;
    margin-left: 0.5rem;
}
.dates-ips {
    display: flex;
}
.searchwrapper {
    position: relative;
}
.searchicons {
    position: absolute;
    top: 10px;
    z-index: 20;
    left: 15px;
}
.search {
    position: relative;
    font-size: 1rem;
    width: 15rem;
    height: 39px;
    padding-bottom: 3px;
    margin: 4px;
    border-radius: 10px;
    border: 1px solid #6865f0;
    text-indent: 2rem;
}
.filter-checkboxs {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    margin-top: 1rem;
    margin-left: 4.6rem;
}
.filter-checkboxs h3 {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    /* identical to box height, or 130% */
    align-self: center;
    letter-spacing: 0.02em;

    color: #252523;
}
.filter-checkbox {
    display: flex;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-items: center;
}
.filter-checkbox input {
    width: 18px;
    height: 18px;
    color: #6865f0;
    border: 1px solid #6865f0;
    box-sizing: border-box;
    border-radius: 4px;
}

.filter-checkbox label {
    font-family: "Rubik";
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 31px;
    /* or 156% */

    letter-spacing: 0.02em;

    color: #52525d;
}
/* Account Profile */
.account-head {
    font-size: 32px;
    font-family: "Rubik";
    font-weight: 600;
    color: #5b5b5a;
    text-align: center;
    margin-top: 40px;
}

.account-box-main {
    width: 90%;
    margin: 24px auto;
    padding: 5px;
    /* background: #FFFFFF; */
    border-radius: 20px;
    text-align: center;
}

.account-box-main1 {
    background: #ffffff;
    padding: 10px 24px;
    border-radius: 24px 24px 0px 0px;
}

.profile-changep-div {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.profile-password-selected {
    border: 0;
    background: none;
    color: #6865f0;
    font-size: 20px;
    font-family: "Rubik";
    font-weight: 500;
    letter-spacing: 0.4px;
    border-bottom: 4px solid #6865f0;
    padding: 0 8px;
}

.profile-password-not-selected {
    border: 0;
    background: none;
    color: #52525d;
    font-size: 20px;
    font-family: "Rubik";
    letter-spacing: 0.4px;
    padding: 0 8px;
}

.account-box-main2 {
    background: #ffffff;
    padding: 10px 24px;
    margin-top: 6px;
}

.user-name-account {
    margin: 0;
    text-align: center;
    font-family: "Rubik";
    color: #252523;
    font-size: 20px;
    font-weight: 500;
}

.user-ref-account {
    margin: 0;
    text-align: center;
    font-family: "Rubik";
    color: #252523;
    font-size: 17px;
    font-weight: 400;
}

.user-details-ips {
    max-width: 420px;
    margin: auto;
    text-align: start;
    margin-top: -30px;
}
.user-details-ips1 {
    max-width: 420px;
    margin: auto;
    text-align: start;
}
.ip-inr-main-profile1 {
    margin-top: 5px;
}
.deduction {
    font-weight: bold;
    color: red;
}
.commission p {
    text-align: center;
    margin-top: 1rem;
    /* font-size: 1.1rem; */
    font-family: sans-serif;
}
.ip-email-p {
    color: #6865f0;
    font-family: "Rubik";
    font-size: 42px;
    font-weight: 400;
    position: relative;
    left: 30px;
    top: 32px;
}

.email-ip-p {
    width: 100%;
    border: 1px solid #6865f0;
    border-radius: 20px;
    height: 40px;
    text-indent: 70px;
    font-size: 20px;
    font-weight: 400;
    color: #252523;
    letter-spacing: 0.8px;
}

.email-ip-p1 {
    width: 100%;
    border: 1px solid #6865f0;
    border-radius: 20px;
    height: 40px;
    font-size: 20px;
    font-weight: 400;
    color: #252523;
    letter-spacing: 0.8px;
    text-indent: 10px;
}
.email-ta-p1 {
    width: 100%;
    border: 1px solid #6865f0;
    border-radius: 20px;
    font-size: 20px;
    font-weight: 400;
    color: #252523;
    letter-spacing: 0.8px;
    text-indent: 10px;
}
.ip-mobile-p {
    color: #6865f0;
    font-family: "Rubik";
    font-size: 42px;
    font-weight: 400;
    position: relative;
    left: 30px;
    top: 34px;
}

.account-box-main3 {
    background: #ffffff;
    padding: 10px 24px;
    margin-top: 6px;
    padding-top: 68px;
}

.ip-password-img {
    color: #6865f0;
    font-family: "Rubik";
    font-size: 42px;
    font-weight: 400;
    position: relative;
    left: 30px;
    top: 40px;
}

.password-ip-p {
    width: 100%;
    border: 1px solid #6865f0;
    border-radius: 20px;
    height: 50px;
    text-indent: 70px;
    font-size: 20px;
    font-weight: 400;
    color: #252523;
    letter-spacing: 0.8px;
}

/* Wallet Transfers */
.walletgrid {
    display: grid;
    grid-template-columns: auto auto auto auto;
    padding: 3rem;
}
.wallet-member {
    position: relative;
    width: 12rem;
}
.search-payers {
    border: 1px solid #6865f0;
    max-width: 440px;
    width: 100%;
    border-radius: 15px;
    color: #52525d;
    font-family: "Rubik";
    font-size: 1rem;
    height: 3rem;
    text-indent: 17px;
}

.mar-top-50 {
    margin-top: 50px;
}

.pay-w-bills-center {
    max-width: 400px;
    margin: 7vh auto;
}

.search-user-btn {
    border: none;
    border-radius: 20px;
    height: 50px;
    font-size: 18px;
    font-weight: 400;
    background: #6865f0;
    letter-spacing: 0.2px;
    margin: 8px 0px;
    outline: none;
    color: white;
    margin-left: 10px;
}

.add-user-dropdown {
    width: 100%;
    border: 1px solid #6865f0d9;
    border-radius: 20px;
    height: 50px !important;
    text-indent: 10px;
    font-size: 20px;
    font-weight: 400;
    color: #6865f0;
    letter-spacing: 0.2px;
    margin: 8px 0px;
    outline: none;
}

.t-details-div {
    padding: 15px;
    border: 3px solid rgba(0, 0, 0, 0.17);
    margin: 15px;
    font-family: "Rubik";
    text-align: left;
    font-weight: 400;
}

.pay-bill-ip1 {
    width: 100%;
    border: 1px solid #6865f0d9;
    border-radius: 20px;
    height: 50px;
    padding: 20px;
    text-indent: 10px;
    font-size: 16px;
    font-weight: 400;
    color: #6865f0;
    letter-spacing: 0.2px;
    margin: 8px 0px;
    outline: none;
}

.titel {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    color: #5b5b5a;
}

.label {
    font-size: 20px;
    font-weight: 600;
    padding: 8px;
}

.resend-btn {
    padding: 4px;
    display: flex;
    margin: auto;
    background: #6865f0;
    color: #ffffff;
}

.pay-url {
    text-align: end;
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-family: "Rubik";
    color: #212121;
}

.ip-inr58 {
    color: #6865f0;
    font-family: "Rubik";
    font-size: 25px;
    font-weight: 400;
    position: relative;
    left: 20px;
    top: 45.5px;
}

.local-search-biller-list {
    width: 85%;
    margin: auto;
}
.delete-payout-btn {
    display: none;
}

.wallet-member:hover .del-btn {
    display: block;
}

.payee-col-sub:hover .delete-payout-btn {
    display: block;
    padding: 0;
    position: absolute;
    right: 4px;
    top: 4px;
    font-size: 14px;
}

.upload-img-png {
    margin: auto;
    width: 112px;
    image-rendering: -webkit-optimize-contrast;
    padding: 5px;
    cursor: pointer;
}

.doc-link-blue {
    color: blue !important;
}
.commissionValue {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.commissionValue::-webkit-outer-spin-button,
.commissionValue::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.commissionValue[type="number"] {
    -moz-appearance: textfield;
}
.referral-modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}
.referral-modal-content {
    display: grid;
    justify-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    opacity: 1;
    max-width: 700px;
    width: 100%;
    padding: 2rem 0;
    background: #ffffff;
    border-radius: 14px;
    position: relative;
}

.referral-modal-content label {
    font-size: 25px;
}
.referral-modal-content input {
    width: 50%;
    height: 3rem;
}
.referral-modal-content input::-webkit-outer-spin-button,
.referral-modal-content input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.referral-modal-content input[type="number"] {
    -moz-appearance: textfield;
}
.referral-modal-content button {
    border: none;
    background-color: #012970;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    font-size: 1.3rem;
}
.custom-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: 4px;
    outline: none;
    padding: 20px;
}
.success-modal-custom {
    display: grid;
    justify-items: center;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    opacity: 1;
    max-width: 900px;
    /* min-width: 400px; */
    /* width: 100%; */
    /* padding: 2rem 0; */
    background: #ffffff;
    position: relative;
    padding: 1rem;
}
.success-modal-custom img {
    margin-bottom: 0.5rem;
}
.success-modal-custom h3 {
    margin: 0;
    font-family: sans-serif;
    padding: 0;
}
.success-modal-custom p {
    color: grey;
}
.success-modal-custom button {
    background: #6865f0;
    border-radius: 20px;
    border: none;
    color: #ffffff;
    font-family: "Rubik";
    padding: 0.5rem 1rem;
}
.uploaded-doc-4 {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}
.uploaded-doc-4 button {
    border: none;
    /* text-decoration: underline; */
    background-color: inherit;
    border-bottom: 1px solid white;
}
.uploaded-doc-4 button:hover {
    border-bottom: 1px solid black;
}
/* Modal Backdrop */
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Modal Content */
.modal-content-iframe {
    padding: 20px;
    border-radius: 8px;
    display: grid;
    align-items: center;
    background-color: white;
    width: 100%;
    height: 100%;
    max-width: 60rem;
    max-height: 40rem;
    justify-items: center;
    position: relative;
}

/* Close Button */
.close-btn-iframe {
    position: absolute;
    top: 10px;
    right: 20px;
    /* font-size: 1rem; */
    cursor: pointer;
    font-size: 28px;
    color: #333;
}
.merry .modal-body {
    display: flex !important;
    background-color: transparent !important;
    align-items: center !important;
    justify-content: center !important;
}


.payment-section{
    display: flex;
    padding-top: 50px;
    border-left: 10px solid rgb(94,98,217);
}

.section1{
    width: 70vw;
    height: 100vh;
}
.section2{
    width: 30vw;
    height: 100vh;
}


.pay-mark-logo{
    width: 150px;
}


.kyc-head{
    margin-top: 50px;
    border-bottom: 3px solid rgb(94,98,217);
    display: inline-block;
    padding-bottom: 20px;
}


.termsandconditons{
    border-bottom: 3px solid lightgray;
}

.bottom-section{
    display: flex;
    flex-direction: column;
    margin-top: 240px;
}




.card-new{
    box-shadow: 0 0 5px rgba(0,0,0,.05), 2px 2px 5px rgba(0,0,0,.1);
    background-color: white;
    position: absolute;
    top: 150px;
    right: 260px;
    width: 500px;
    height: 400px;
}


.pre-form-error-text{
    color: red;
    font-size: 12px;
}


.card-new-heading{
    margin-left: 20px;
    margin-top: 20px;
    border-bottom: 3px solid rgb(94,98,217);
    display: inline-block;
    padding-bottom: 15px;
}


.pre-form{
    margin-top: 20px;
}

.pre-form-section{
    display: flex;
    justify-content: space-between;


}

.pay-button{
    background-color: rgb(94,98,217);
    height: 60px;;
    width: 100%

}



.pay-button-section{
    background-color: #f5f6f7;
    height: 60px;
    margin-top: 10px;
}


.section2-img{
    position: absolute;
    top: 0;
    right: 0;
    height: 110vh;
    width: 30vw;
    z-index: -20;
}


.section2-img2{
    width: 200px;
    margin-top: 25px;
    margin-left: 30px;
}




@media only screen and (max-width: 700px) {
   .card-new{
       top:220px;
       right:50px;
       width: 19rem;
   }


   .bottom-section{
    margin-top: 500px;
   }
  }
/* ---Bbps NavBar */

.paymentNav {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1.3rem;
    /* line-height: 22px; */
    letter-spacing: 0.02em;
}

.billNavactive {
    background: #6865f0;
    border: 4px solid #ffffff;
    box-sizing: border-box;
    border-radius: 16px;
    font-family: "Rubik";
    color: white !important;
    padding: 0.5rem 1rem;
    font-size: 16px;
}

.paymentNav .billNavinactive {
    border-radius: 16px;
    box-sizing: border-box;
    padding: 0.5rem 1rem;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.02em;
    color: #252523 !important;
    background: rgba(255, 255, 255, 0.84);
    border: 4px solid #ffffff;
}

/* --------------complaint-status-fetch---- */

/* ------complaint status --------- */
.Complaint-status {
    justify-self: center;
    padding: 2rem 5rem;
    display: grid;
    grid-gap: 3rem;
    gap: 3rem;
    background-color: white;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    /* min-width: 45rem !important; */
    justify-items: center;
}
.Complaint-status h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 44px;
    line-height: 22px;
    /* identical to box height, or 50% */

    letter-spacing: 0.02em;

    color: #30303c;
}
.Complaint-status-item {
    /* justify-items: center; */
    display: grid;
    grid-template-columns: 55% 2% 40%;
    grid-gap: 2rem;
    gap: 2rem;
}
.Complaint-status-item h3,
.Complaint-status-item h5 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    letter-spacing: 0.02em;

    color: #52525d;
}
.Complaint-status-item h5 {
    font-weight: 500;
}
.complaint-register {
    display: grid;
    justify-items: center;
}

/* -------------------------------------- */

.bbps-items {
    width: 200px;
    height: 150px;
}

.head-bbps {
    color: #5b5b5a;
    font-family: "Rubik";
    font-weight: 600;
    font-size: 30px;
    display: inline-block;
}

.bharat-pay {
    height: 50px;
}

.bbps-section1 {
    margin-top: 50px;
}

.header-bbps {
    margin-top: 5rem;
    width: 100%;
    margin-left: 0.5rem;
    /* margin: 5rem 0 1rem 2rem; */
}
.BbpsWrapper {
    display: grid;
    grid-gap: 2rem;
    gap: 2rem;
    background-color: #f8f8f8;
}
.Bbps-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Bbps-heading img {
    align-self: center;
    margin: 0;
}
.Bbps-heading p {
    font-family: "Rubik";
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    margin: 0;
    /* identical to box height, or 74% */
    align-self: center;
    letter-spacing: 0.02em;

    color: #5b5b5a;
}
.paymentType-wrapper {
    display: flex;
    flex-wrap: wrap;
    grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
    justify-items: left;
    grid-gap: 1rem;
    gap: 1rem;
    margin: 2rem 0;
    grid-row-gap: 2rem;
    row-gap: 2rem;
}
.payment-type {
    display: grid;
    grid-gap: 1rem;
    gap: 1rem;
    width: 170px;
    justify-items: center;

    background: #ffffff;
    padding: 1rem;
    border: none;
}
.paymentType_icon {
    /* width: 10%; */
    width: 50px;
    height: 50px;
    align-self: center;
}

.payment-type h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    /* line-height: 22px; */
    /* width: 60%; */
    /* or 110% */

    text-align: center;
    letter-spacing: 0.02em;
    color: #252523;
}
/* ------ Bbpsbillrequest */

.biller-details-wrapper {
    display: grid;
}
.biller-details {
    display: grid;
    grid-gap: 2rem;
    gap: 2rem;
    background-color: white;
    padding: 5rem 0 2rem 0;
    border-radius: 10px;
    width: 90%;
    margin: auto;
    /* margin: 5rem;
    max-width: 50rem; */
    /* margin-left: 17rem; */
}
.biller-detail {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.biller-details .biller-detail input {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    width: 60%;
    height: 3.5rem;
    text-indent: 2rem;
    border-radius: 7px;
    text-indent: 2rem;
    font-size: 1.2rem;
    border: 1px solid #6865f0;
}
.biller-details .biller-detail #billerinput:focus {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}
.biller-details .biller-detail input::-webkit-outer-spin-button,
.biller-details .biller-detail input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.biller-details .biller-detail input[type="number"] {
    -moz-appearance: textfield;
}
.biller-details .select {
    background-color: white;
    display: block;
    border: 1px solid #6865f0;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    width: 60%;
    height: 3.5rem;
    border-radius: 7px;
    text-indent: 1rem;
    /* max-width: 50rem; */
    background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
        no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    /* padding-right: 2rem !important; */
    height: 3.6rem;
    font-size: 1.3rem;
    line-height: 41px;
    text-indent: 2rem;
}
.biller-details button {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    justify-self: center;
    /* margin-right: 5rem; */
    background: #6865f0;
    box-shadow: 0px 4px 40px rgba(60, 0, 125, 0.41);
    border-radius: 10px;
    border: none;
    color: white;
    padding: 0.7rem 5rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    letter-spacing: 0.01em;
}
/* Bbpspay */
.amount-wrapper {
    display: grid;

    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-gap: 1rem;
    gap: 1rem;
    background-color: white;
    grid-auto-flow: dense;
    padding: 2rem;
}
.amount-data label {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    /* or 92% */

    letter-spacing: 0.02em;

    color: #52525d;
}
.amount-data {
    display: grid;
    grid-gap: 1rem;
    gap: 1rem;
}
.amount-wrapper .amount-data input {
    border: 1px solid #6d6d6b;
    text-indent: 1rem;
    width: 80%;
    height: 3rem;
    border-radius: 7px;
}
.amount-wrapper .amount-data input:-ms-input-placeholder {
    color: #131312;
    /* color: #52525d; */
}
.amount-wrapper .amount-data input::placeholder {
    color: #131312;
    /* color: #52525d; */
}
.amount-wrapper .amount-data input[type="text"][disabled] {
    background-color: rgba(128, 128, 128, 0.5);
}
.amount-wrapper .amount-data select {
    background-color: white;
    display: block;
    border: 1px solid grey;

    height: 3rem;
    border-radius: 7px;
    text-indent: 1rem;
    /* max-width: 50rem; */
    background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
        no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    /* padding-right: 2rem !important; */
    height: 3.6rem;
    font-size: 1.3rem;
    line-height: 41px;
    width: 80%;
}
.amount-wrapper .amount-data select option {
    background-color: white !important;
}
.amount-wrapper button {
    /* grid-column: 1/3; */
    grid-column: -1/1;
    align-self: center;
    justify-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    justify-self: center;
    /* margin-right: 5rem; */
    background: #6865f0;
    box-shadow: 0px 4px 40px rgba(60, 0, 125, 0.41);
    border-radius: 10px;
    border: none;
    color: white;
    padding: 0.7rem 5rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    letter-spacing: 0.01em;
    margin-right: 4rem;
    margin-top: 2rem;
}
.amount-wrapper button:active {
    background-color: white;
    color: #6865f0;
}
/* ----Bbpsuccess */
.successWrappe {
    display: grid;
    justify-items: center;
    margin: 3rem 0;
    padding-bottom: 1rem;
}
.successWrapper {
    padding: 1rem 3rem 1rem 2rem;
    background-color: white;
    display: grid;
    grid-gap: 1rem;
    gap: 1rem;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    width: 100%;
    max-width: 45rem;
    margin-bottom: 3rem;
}
.successWrapper img {
    align-self: center;
    width: 10%;
    justify-self: end;
}
.successWrapper > p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    /* line-height: 22px; */
    /* identical to box height, or 55% */

    letter-spacing: 0.02em;

    color: #30303c;
}
.success-details {
    width: 100%;
    max-width: 30rem;
}
.success-detail P {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    /* or 110% */

    letter-spacing: 0.02em;

    color: #52525d;
}

.success-detail {
    display: grid;
    grid-template-columns: 0.8fr 0.5fr 1fr;
    align-items: center;
}

.success-detail p:nth-child(2) {
    justify-self: center;
}
/* ---- transactions */

.TranscationWrapper {
    background-color: #f6f7ff;
    display: grid;
    grid-gap: 2rem;
    gap: 2rem;
    /* padding: 2rem; */
}

.filter {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    align-items: center;
    grid-gap: 2rem;
    gap: 2rem;
}
.filter-control {
    display: grid;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
}
.filter-control input[type="date"]::-webkit-inner-spin-button,
.filter-control input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}
.filter-control input::-webkit-outer-spin-button,
.filter-control input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.filter-control input[type="number"] {
    -moz-appearance: textfield;
}
.filter .filter-control .input {
    margin: 0;
    border: 1px solid grey;
    text-indent: 0.5rem;
    box-shadow: none;
    width: 100%;
    border-radius: 10px;
    height: 3rem;
}
input:focus {
    border: 1px solid black;
    box-shadow: none;
    outline: none;
}
.filter-control label {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    letter-spacing: 0.02em;

    color: #52525d;
}
.transcations {
    display: grid;
    grid-gap: 2rem;
    gap: 2rem;
    background-color: white;
    padding: 1rem;
    margin: 0 1.5rem;
}
.transcations table tr {
    border-bottom: 1px solid #e4e4e4;
    /* background: #e4e4e4; */
}

.transcations table tr td,
.transcations table tr th {
    padding: 1rem;
}
.transcations table tr th {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.01em;

    color: #252523;
}
.transcations table tr td {
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #52525d;
}
.transcations table tr td button {
    border: none;
    background-color: white;
}
.filter button {
    color: white;
    background: #6865f0;
    border: none;
    padding: 0.5rem 2rem;
    border-radius: 10px;
    font-size: 1.4rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    align-self: end;
}
/*  submenu-complaint*/
.Complaint-submenu {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
}

.Complaint-submenu .Complaintactive {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    /* line-height: 22px; */
    letter-spacing: 0.02em;
    color: #6865f0 !important;
    padding-bottom: 0.5rem;
    border-bottom: 6px solid #6865f0;
    display: block;
}
.Complaint-submenu .Complaintinactive {
    font-family: "Rubik";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    /* line-height: 22px; */
    /* or 110% */
    color: #6d6d6b !important;
    letter-spacing: 0.02em;
}

/* ----complaint */
.complaint-register {
    display: grid;
    justify-items: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 4rem 0;
}
.Complaint-register-item {
    display: grid;
    grid-template-columns: 0.6fr 0.1fr 0.3fr;
    grid-gap: 2rem;
    gap: 2rem;
    align-items: center;
}
.Complaint-register-item > h3,
.Complaint-register-item > h5 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 22px;
    /* or 92% */
    min-width: 14rem;
    letter-spacing: 0.02em;

    color: #52525d;
}
.Complaint-formwrapper {
    display: grid;
    grid-gap: 2rem;
    gap: 2rem;
    background-color: white;
    padding: 2rem;
    /* padding-bottom: 10rem; */
}
.Complaint-formwrapper h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 2rem;
    /* line-height: 22px; */
    /* identical to box height, or 50% */

    letter-spacing: 0.02em;

    color: #30303c;
}
.Complaint-formwrapper form {
    display: grid;
    /* grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); */
    grid-gap: 1rem;
    gap: 1rem;
}
.Complaint-formwrapper form .button {
    align-self: end;
    justify-self: end;
    grid-column: 1/-1;
    padding-top: 2rem;
}
.Complaint-formwrapper form .button button {
    background: #010080;
    color: white;
    border: none;
    padding: 1.5rem 4rem;
    border-radius: 40px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 22px;
}
.Complaint-formwrapper form .button button:active {
    background-color: white;
    color: #010080;
    border: 1px solid #010080;
}
.Complaint-form-control {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    /* justify-content: center; */
}
.Complaint-form-control label {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    /* line-height: 22px; */
    /* or 92% */

    letter-spacing: 0.02em;

    color: #52525d;
}
.Complaint-formwrapper form .Complaint-form-control .input {
    border: 1px solid #52525d;
    width: 90%;
    height: 3.5rem;
    border-radius: 7px;
    text-indent: 1rem;
}
.Complaint-formwrapper form .Complaint-form-control .textarea {
    border: 1px solid #52525d;
    width: 90%;

    border-radius: 7px;
    text-indent: 1rem;
    height: 8rem;
}
.Complaint-formwrapper form .Complaint-form-control .input:focus {
    border: 1px solid black;
    box-shadow: none;
    outline: none;
}
.Complaint-form-control input::-webkit-outer-spin-button,
.Complaint-form-control input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.Complaint-form-control input[type="number"] {
    -moz-appearance: textfield;
}
.Complaint-form-control .textarea {
    resize: none;
    /* height: 5rem; */
    border-radius: 7px;
}
.TypeOfComplaint-wrapper {
    display: grid;
    grid-gap: 1rem;
    gap: 1rem;
}
.TypeOfComplaint-wrapper label {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    /* line-height: 22px; */
    letter-spacing: 0.02em;
    color: #52525d;
}
.complaint-type {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
}
.complaint-type p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    /* or 92% */

    letter-spacing: 0.02em;
    margin: 0;
    color: #52525d;
}
.complaint-type input {
    opacity: 1 !important;
    position: unset !important;
    pointer-events: initial !important;
}
.Complaint-form-control .select option {
    max-width: 500px;
    height: 5rem;
}
.Complaint-form-control .select,
.Complaint-form-control .select option {
    display: block;
    border: 1px solid #52525d;
    width: 90% !important;
    overflow: hidden;
    height: 3.5rem;
    border-radius: 7px;
    text-indent: 1rem;

    background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
        no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 2rem !important;
    height: 3.6rem;
    font-size: 1.3rem;
    line-height: 41px;
    /* text-indent: 3.5rem; */
}
.modal-spinner {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
}
.modal-content-spinner {
    display: grid;
    justify-items: center;
    grid-gap: 2rem;
    gap: 2rem;
    opacity: 1;
    max-width: 700px;
    width: 100%;
    padding: 2rem 0;
    position: relative;
}
/* ----------search-transaction--------------- */
.Complaint-formwrapper .search-transaction {
    margin-left: 5rem !important;
    display: grid;
    grid-template-columns: 0.5fr 0.3fr !important;
    /* flex-direction: row !important; */
}
.Complaint-formwrapper .search-transaction button {
    background: #010080;
    color: white;
    border: none;
    align-self: center;
    margin-top: 2rem;
    padding: 1rem 4rem;
    border-radius: 40px;
    font-family: "Poppins";
    font-style: normal;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 22px;
}
.search-transaction-wrapper {
    display: grid;
    grid-gap: 3rem;
    gap: 3rem;
}
.search-transaction-wrapper .or {
    justify-self: center;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 22px;
    /* identical to box height, or 73% */

    letter-spacing: 0.02em;

    color: #30303c;
}
.search-transaction-form-two {
    margin-left: 5rem !important;
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr 1fr !important;
    align-items: center;
}
.search-transaction-form-two button {
    background: #010080;
    color: white;
    border: none;
    align-self: center;
    margin-top: 2rem;
    padding: 1rem 4rem;
    border-radius: 40px;
    font-family: "Poppins";
    font-style: normal;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 22px;
}

@media screen and (max-width: 900px) {
    .search-transaction-form-two,
    .search-transaction {
        display: grid;
        grid-template-columns: 1fr !important;
    }
}
/* input[type="date"] {
    display: flex;
    flex-flow: row-reverse;
    padding: 8px;
    height: auto;
    text-indent: 2rem;
} */

.search-transaction-form-two
    .input[type="date"]::-webkit-calendar-picker-indicator {
    margin-right: 10px;
}
.biller-name {
    text-align: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: bold;
    font-size: 2.4rem;
    /* line-height: 40px; */
    letter-spacing: 0.01em;
    color: #30303c;
}

.section1{
    height: 100vh;
    width: 100%;
    background: #FEFAF1;

}



.ourservices{
    margin-top: 40vh;
    width: 600px;
}

.man-image{
    position: absolute;
    bottom:0;
    height: 550px;
    right: 200px;
    /* width: 100%; */
    z-index: 5;
}

.circle-behind-man{
    position: absolute;
    bottom:0;
    height: 430px;
    right: 180px;
    z-index:1
}

.service-items{
    width: 350px;
    height: 450px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
}


.services-item-icons{
    height:"10px" !important;
    width:'10px' !important;
    margin-bottom: 20px;
}



.card-content{
    margin-top: 60px;
    margin-bottom: 80px;
}



.heading{
    font-family: Rubik;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.02em;

}

.description{
    font-family: Rubik;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.02em;
    color: #52525D;
}


.set{
    margin-bottom: 60px;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Josefin Sans", sans-serif;
}
.Saved_row_header__3gQy1 {
    display: flex;
    justify-content: space-between;
    margin: 2rem;
}
.Saved_Card_wrapper__2Mgf2 {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    flex-wrap: wrap;
    margin: 2rem;
}
.Saved_container__2Qlfi {
    display: flex;
    align-items: center;
    justify-content: center;
}
.Saved_row_header__3gQy1 button {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border: transparent;
    background-color: #3340b8;
    color: white;
    border-radius: 5px;
}
.Saved_card__37r5- {
    width: 320px;
    height: 180px;
    color: #fff;
    cursor: pointer;
    perspective: 1000px;
    position: relative;
}
.Saved_Delete__1EFeH {
    position: absolute;
    z-index: 20;
    right: 5px;
    top: 5px;
    border: none;
    background-color: red;
    color: white;
    padding: 0.2rem;
    border-radius: 10px;
    font-size: 0.9rem;
}

.Saved_card-inner__5rbFb {
    width: 100%;
    height: 100%;
    position: relative;
    transition: transform 1s;
    transform-style: preserve-3d;
}

.Saved_front__1eQB1,
.Saved_back__3pHTs {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(45deg, #0045c7, #ff2c7d);
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px 30px;
    border-radius: 15px;
    overflow: hidden;
    z-index: 1;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}

.Saved_row__2m0vV {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Saved_map-img__Y_wBX {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    z-index: -1;
}

.Saved_card-no__3-jUH {
    font-size: 19px;
    margin-top: 12px;
}
.Saved_name__25Ap5 p,
.Saved_card-no__3-jUH p,
.Saved_card-holder__3ZCpy p {
    margin-bottom: 0 !important;
}
.Saved_card-holder__3ZCpy {
    font-size: 12px;
    margin-top: 12px;
}

.Saved_name__25Ap5 {
    font-size: 15px;
    margin-top: 2px;
}

.Saved_bar__10ama {
    background: #222;
    margin-left: -30px;
    margin-right: -30px;
    height: 60px;
    margin-top: 10px;
}

.Saved_card-cvv__1CTYi {
    margin-top: 20px;
}

.Saved_card-cvv__1CTYi div {
    flex: 1 1;
}

.Saved_card-cvv__1CTYi img {
    width: 100%;
    display: block;
    line-height: 0;
}

.Saved_card-cvv__1CTYi p {
    background: #fff;
    color: #000;
    font-size: 22px;
    padding: 10px 20px;
}

.Saved_card-text__6M3Jo {
    margin-top: 30px;
    font-size: 14px;
}

.Saved_signature__3PnGU {
    margin-top: 30px;
}

.Saved_back__3pHTs {
    transform: rotateY(180deg);
}
.Saved_Card_wrapper__2Mgf2 form {
    display: grid;
    grid-gap: 1rem;
    gap: 1rem;
    padding: 1rem 2rem;
    border-radius: 10px;
    justify-items: center;
}
.Saved_form_control__1c7KR {
    display: grid;
    grid-gap: 0.7rem;
    gap: 0.7rem;
    width: 500px;
}
.Saved_Card_wrapper__2Mgf2 form h3 {
    text-align: center;
}
.Saved_form_control__1c7KR input {
    border: 1px solid #ccc;
    text-indent: 0.6rem;
    height: 2.5rem;
    border-radius: 10px;
    width: 100%;
}
.Saved_input__2myW- {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    width: 100%;
    /* position: relative; */
}
.Saved_input__2myW- div {
    position: relative;
    width: 100%;
}
.Saved_verified__2B1yl {
    position: absolute;
    right: 8px;
    color: green;
    top: 10px;
}
.Saved_input__2myW- button {
    border: 1px solid #ccc;
    padding: 0 1rem;
}
.Saved_Card_wrapper__2Mgf2 form > button {
    padding: 0.5rem 1rem;
    border: none;
}
.Saved_Modal_wrapper__3VcW0 {
    background-color: white;
    padding: 0.5rem;
    border-radius: 10px;
    display: grid;
}
.Saved_Modal_wrapper__3VcW0 h3 {
    font-weight: 500 !important;
}
.Saved_error__ixkr5 {
    margin-bottom: 0 !important;
}
.Saved_Modal_wrapper__3VcW0 .Saved_form_control__1c7KR,
.Saved_otp__1pJ5E {
    display: grid;
    justify-items: left;
}
.Saved_Modal_wrapper__3VcW0 input {
    width: 90%;
}
.Saved_Modal_wrapper__3VcW0 form button {
    padding: 0.3rem 1rem;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin: 1rem 0;
}
.Saved_otp__1pJ5E input {
    width: 50px !important;
    height: 50px !important;
}
.Saved_Card_success_wrapper__3rWX2 {
    display: grid;
    justify-items: center;
}
.Saved_Card-details__2xy7z {
    display: grid;
    grid-gap: 0rem;
    gap: 0rem;
    margin-top: 1rem;
    width: 90%;
}
.Saved_Card-details__2xy7z div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border: 1px solid black;
    align-items: center;
    /* justify-items: center; */
}
.Saved_Card-details__2xy7z h4 {
    background-color: lightgray;
    padding: 0.7rem 0;
    align-self: center;
    font-size: 1rem;
    margin-bottom: 0 !important;
}

.Saved_Card-details__2xy7z p {
    margin-bottom: 0 !important;
}
.Saved_input_search__3VXXG {
    display: flex;
    margin-left: 2.2rem;
    height: 2.5rem;
    width: 100%;
    max-width: 400px;
    border-radius: 10px;
    text-indent: 10px;
    border: 1px solid #ccc;
    outline: #ccc !important;
}
.Saved_input_search__3VXXG:focus {
    outline: none !important;
    border-color: #ccc;
}

.loader_Loader-wrapper__2rGNw {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* bottom: -70px; */
    background-color: rgba(0, 0, 0, 0.4);
    display: grid;
    align-items: center;
    justify-items: center;
    z-index: 10;
}
.loader_loader__2BoVf {
    z-index: 10;
    overflow: hidden !important;
}
.loader_Table__cRr6i {
    display: grid;
    width: 100%;
    overflow: auto;
}
.loader_Table__cRr6i table {
    border-collapse: collapse;
    table-layout: auto;
    width: 100%;
    text-align: left;
    border: 1px solid #ccc;
    min-width: 600px;
}
.loader_Table__cRr6i table thead {
    color: white;
}
.loader_Table__cRr6i table thead th {
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    background-color: #1e87db;
    padding: 1rem 0rem;
    /* letter-spacing: 0.05rem; */
    text-transform: uppercase;
    vertical-align: middle;
    text-align: center;
}
.loader_Table__cRr6i table tbody tr {
    text-align: center;
    cursor: pointer;
    color: #777;
}
.loader_Table__cRr6i table tbody tr td {
    border-bottom: 1px solid #ccc;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    padding: 1rem 0.5rem;
    color: inherit;
    cursor: pointer;
    text-transform: capitalize;
}

