body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
.Pg_charges_table table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    border: 1px solid black;
    text-align: center;
}

.Pg_charges_table table td,
.Pg_charges_table table th {
    border: 1px solid black;
    padding: 0.3rem;
}
