.section1{
    height: 100vh;
    width: 100%;
    background: #FEFAF1;

}



.ourservices{
    margin-top: 40vh;
    width: 600px;
}

.man-image{
    position: absolute;
    bottom:0;
    height: 550px;
    right: 200px;
    /* width: 100%; */
    z-index: 5;
}

.circle-behind-man{
    position: absolute;
    bottom:0;
    height: 430px;
    right: 180px;
    z-index:1
}

.service-items{
    width: 350px;
    height: 450px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
}


.services-item-icons{
    height:"10px" !important;
    width:'10px' !important;
    margin-bottom: 20px;
}



.card-content{
    margin-top: 60px;
    margin-bottom: 80px;
}



.heading{
    font-family: Rubik;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.02em;

}

.description{
    font-family: Rubik;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.02em;
    color: #52525D;
}


.set{
    margin-bottom: 60px;
}