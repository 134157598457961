/* ---Bbps NavBar */

.paymentNav {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1.3rem;
    /* line-height: 22px; */
    letter-spacing: 0.02em;
}

.billNavactive {
    background: #6865f0;
    border: 4px solid #ffffff;
    box-sizing: border-box;
    border-radius: 16px;
    font-family: "Rubik";
    color: white !important;
    padding: 0.5rem 1rem;
    font-size: 16px;
}

.paymentNav .billNavinactive {
    border-radius: 16px;
    box-sizing: border-box;
    padding: 0.5rem 1rem;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.02em;
    color: #252523 !important;
    background: rgba(255, 255, 255, 0.84);
    border: 4px solid #ffffff;
}

/* --------------complaint-status-fetch---- */

/* ------complaint status --------- */
.Complaint-status {
    justify-self: center;
    padding: 2rem 5rem;
    display: grid;
    gap: 3rem;
    background-color: white;
    height: fit-content;
    width: fit-content;
    /* min-width: 45rem !important; */
    justify-items: center;
}
.Complaint-status h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 44px;
    line-height: 22px;
    /* identical to box height, or 50% */

    letter-spacing: 0.02em;

    color: #30303c;
}
.Complaint-status-item {
    /* justify-items: center; */
    display: grid;
    grid-template-columns: 55% 2% 40%;
    gap: 2rem;
}
.Complaint-status-item h3,
.Complaint-status-item h5 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    letter-spacing: 0.02em;

    color: #52525d;
}
.Complaint-status-item h5 {
    font-weight: 500;
}
.complaint-register {
    display: grid;
    justify-items: center;
}

/* -------------------------------------- */

.bbps-items {
    width: 200px;
    height: 150px;
}

.head-bbps {
    color: #5b5b5a;
    font-family: "Rubik";
    font-weight: 600;
    font-size: 30px;
    display: inline-block;
}

.bharat-pay {
    height: 50px;
}

.bbps-section1 {
    margin-top: 50px;
}

.header-bbps {
    margin-top: 5rem;
    width: 100%;
    margin-left: 0.5rem;
    /* margin: 5rem 0 1rem 2rem; */
}
.BbpsWrapper {
    display: grid;
    gap: 2rem;
    background-color: #f8f8f8;
}
.Bbps-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Bbps-heading img {
    align-self: center;
    margin: 0;
}
.Bbps-heading p {
    font-family: "Rubik";
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    margin: 0;
    /* identical to box height, or 74% */
    align-self: center;
    letter-spacing: 0.02em;

    color: #5b5b5a;
}
.paymentType-wrapper {
    display: flex;
    flex-wrap: wrap;
    grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
    justify-items: left;
    gap: 1rem;
    margin: 2rem 0;
    row-gap: 2rem;
}
.payment-type {
    display: grid;
    gap: 1rem;
    width: 170px;
    justify-items: center;

    background: #ffffff;
    padding: 1rem;
    border: none;
}
.paymentType_icon {
    /* width: 10%; */
    width: 50px;
    height: 50px;
    align-self: center;
}

.payment-type h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    /* line-height: 22px; */
    /* width: 60%; */
    /* or 110% */

    text-align: center;
    letter-spacing: 0.02em;
    color: #252523;
}
/* ------ Bbpsbillrequest */

.biller-details-wrapper {
    display: grid;
}
.biller-details {
    display: grid;
    gap: 2rem;
    background-color: white;
    padding: 5rem 0 2rem 0;
    border-radius: 10px;
    width: 90%;
    margin: auto;
    /* margin: 5rem;
    max-width: 50rem; */
    /* margin-left: 17rem; */
}
.biller-detail {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.biller-details .biller-detail input {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    width: 60%;
    height: 3.5rem;
    text-indent: 2rem;
    border-radius: 7px;
    text-indent: 2rem;
    font-size: 1.2rem;
    border: 1px solid #6865f0;
}
.biller-details .biller-detail #billerinput:focus {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}
.biller-details .biller-detail input::-webkit-outer-spin-button,
.biller-details .biller-detail input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.biller-details .biller-detail input[type="number"] {
    -moz-appearance: textfield;
}
.biller-details .select {
    background-color: white;
    display: block;
    border: 1px solid #6865f0;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    width: 60%;
    height: 3.5rem;
    border-radius: 7px;
    text-indent: 1rem;
    /* max-width: 50rem; */
    background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
        no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    /* padding-right: 2rem !important; */
    height: 3.6rem;
    font-size: 1.3rem;
    line-height: 41px;
    text-indent: 2rem;
}
.biller-details button {
    width: fit-content;
    height: fit-content;
    justify-self: center;
    /* margin-right: 5rem; */
    background: #6865f0;
    box-shadow: 0px 4px 40px rgba(60, 0, 125, 0.41);
    border-radius: 10px;
    border: none;
    color: white;
    padding: 0.7rem 5rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    letter-spacing: 0.01em;
}
/* Bbpspay */
.amount-wrapper {
    display: grid;

    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 1rem;
    background-color: white;
    grid-auto-flow: dense;
    padding: 2rem;
}
.amount-data label {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    /* or 92% */

    letter-spacing: 0.02em;

    color: #52525d;
}
.amount-data {
    display: grid;
    gap: 1rem;
}
.amount-wrapper .amount-data input {
    border: 1px solid #6d6d6b;
    text-indent: 1rem;
    width: 80%;
    height: 3rem;
    border-radius: 7px;
}
.amount-wrapper .amount-data input::placeholder {
    color: #131312;
    /* color: #52525d; */
}
.amount-wrapper .amount-data input[type="text"][disabled] {
    background-color: rgba(128, 128, 128, 0.5);
}
.amount-wrapper .amount-data select {
    background-color: white;
    display: block;
    border: 1px solid grey;

    height: 3rem;
    border-radius: 7px;
    text-indent: 1rem;
    /* max-width: 50rem; */
    background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
        no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    /* padding-right: 2rem !important; */
    height: 3.6rem;
    font-size: 1.3rem;
    line-height: 41px;
    width: 80%;
}
.amount-wrapper .amount-data select option {
    background-color: white !important;
}
.amount-wrapper button {
    /* grid-column: 1/3; */
    grid-column: -1/1;
    align-self: center;
    justify-items: center;
    width: fit-content;
    height: fit-content;
    justify-self: center;
    /* margin-right: 5rem; */
    background: #6865f0;
    box-shadow: 0px 4px 40px rgba(60, 0, 125, 0.41);
    border-radius: 10px;
    border: none;
    color: white;
    padding: 0.7rem 5rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    letter-spacing: 0.01em;
    margin-right: 4rem;
    margin-top: 2rem;
}
.amount-wrapper button:active {
    background-color: white;
    color: #6865f0;
}
/* ----Bbpsuccess */
.successWrappe {
    display: grid;
    justify-items: center;
    margin: 3rem 0;
    padding-bottom: 1rem;
}
.successWrapper {
    padding: 1rem 3rem 1rem 2rem;
    background-color: white;
    display: grid;
    gap: 1rem;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    width: 100%;
    max-width: 45rem;
    margin-bottom: 3rem;
}
.successWrapper img {
    align-self: center;
    width: 10%;
    justify-self: end;
}
.successWrapper > p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    /* line-height: 22px; */
    /* identical to box height, or 55% */

    letter-spacing: 0.02em;

    color: #30303c;
}
.success-details {
    width: 100%;
    max-width: 30rem;
}
.success-detail P {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    /* or 110% */

    letter-spacing: 0.02em;

    color: #52525d;
}

.success-detail {
    display: grid;
    grid-template-columns: 0.8fr 0.5fr 1fr;
    align-items: center;
}

.success-detail p:nth-child(2) {
    justify-self: center;
}
/* ---- transactions */

.TranscationWrapper {
    background-color: #f6f7ff;
    display: grid;
    gap: 2rem;
    /* padding: 2rem; */
}

.filter {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    align-items: center;
    gap: 2rem;
}
.filter-control {
    display: grid;
    gap: 1rem;
    align-items: center;
}
.filter-control input[type="date"]::-webkit-inner-spin-button,
.filter-control input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}
.filter-control input::-webkit-outer-spin-button,
.filter-control input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.filter-control input[type="number"] {
    -moz-appearance: textfield;
}
.filter .filter-control .input {
    margin: 0;
    border: 1px solid grey;
    text-indent: 0.5rem;
    box-shadow: none;
    width: 100%;
    border-radius: 10px;
    height: 3rem;
}
input:focus {
    border: 1px solid black;
    box-shadow: none;
    outline: none;
}
.filter-control label {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    letter-spacing: 0.02em;

    color: #52525d;
}
.transcations {
    display: grid;
    gap: 2rem;
    background-color: white;
    padding: 1rem;
    margin: 0 1.5rem;
}
.transcations table tr {
    border-bottom: 1px solid #e4e4e4;
    /* background: #e4e4e4; */
}

.transcations table tr td,
.transcations table tr th {
    padding: 1rem;
}
.transcations table tr th {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.01em;

    color: #252523;
}
.transcations table tr td {
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #52525d;
}
.transcations table tr td button {
    border: none;
    background-color: white;
}
.filter button {
    color: white;
    background: #6865f0;
    border: none;
    padding: 0.5rem 2rem;
    border-radius: 10px;
    font-size: 1.4rem;
    width: fit-content;
    height: fit-content;
    align-self: end;
}
/*  submenu-complaint*/
.Complaint-submenu {
    display: flex;
    gap: 2rem;
}

.Complaint-submenu .Complaintactive {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    /* line-height: 22px; */
    letter-spacing: 0.02em;
    color: #6865f0 !important;
    padding-bottom: 0.5rem;
    border-bottom: 6px solid #6865f0;
    display: block;
}
.Complaint-submenu .Complaintinactive {
    font-family: "Rubik";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    /* line-height: 22px; */
    /* or 110% */
    color: #6d6d6b !important;
    letter-spacing: 0.02em;
}

/* ----complaint */
.complaint-register {
    display: grid;
    justify-items: center;
    height: fit-content;
    padding: 4rem 0;
}
.Complaint-register-item {
    display: grid;
    grid-template-columns: 0.6fr 0.1fr 0.3fr;
    gap: 2rem;
    align-items: center;
}
.Complaint-register-item > h3,
.Complaint-register-item > h5 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 22px;
    /* or 92% */
    min-width: 14rem;
    letter-spacing: 0.02em;

    color: #52525d;
}
.Complaint-formwrapper {
    display: grid;
    gap: 2rem;
    background-color: white;
    padding: 2rem;
    /* padding-bottom: 10rem; */
}
.Complaint-formwrapper h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 2rem;
    /* line-height: 22px; */
    /* identical to box height, or 50% */

    letter-spacing: 0.02em;

    color: #30303c;
}
.Complaint-formwrapper form {
    display: grid;
    /* grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); */
    gap: 1rem;
}
.Complaint-formwrapper form .button {
    align-self: end;
    justify-self: end;
    grid-column: 1/-1;
    padding-top: 2rem;
}
.Complaint-formwrapper form .button button {
    background: #010080;
    color: white;
    border: none;
    padding: 1.5rem 4rem;
    border-radius: 40px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 22px;
}
.Complaint-formwrapper form .button button:active {
    background-color: white;
    color: #010080;
    border: 1px solid #010080;
}
.Complaint-form-control {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* justify-content: center; */
}
.Complaint-form-control label {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    /* line-height: 22px; */
    /* or 92% */

    letter-spacing: 0.02em;

    color: #52525d;
}
.Complaint-formwrapper form .Complaint-form-control .input {
    border: 1px solid #52525d;
    width: 90%;
    height: 3.5rem;
    border-radius: 7px;
    text-indent: 1rem;
}
.Complaint-formwrapper form .Complaint-form-control .textarea {
    border: 1px solid #52525d;
    width: 90%;

    border-radius: 7px;
    text-indent: 1rem;
    height: 8rem;
}
.Complaint-formwrapper form .Complaint-form-control .input:focus {
    border: 1px solid black;
    box-shadow: none;
    outline: none;
}
.Complaint-form-control input::-webkit-outer-spin-button,
.Complaint-form-control input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.Complaint-form-control input[type="number"] {
    -moz-appearance: textfield;
}
.Complaint-form-control .textarea {
    resize: none;
    /* height: 5rem; */
    border-radius: 7px;
}
.TypeOfComplaint-wrapper {
    display: grid;
    gap: 1rem;
}
.TypeOfComplaint-wrapper label {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    /* line-height: 22px; */
    letter-spacing: 0.02em;
    color: #52525d;
}
.complaint-type {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.complaint-type p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    /* or 92% */

    letter-spacing: 0.02em;
    margin: 0;
    color: #52525d;
}
.complaint-type input {
    opacity: 1 !important;
    position: unset !important;
    pointer-events: initial !important;
}
.Complaint-form-control .select option {
    max-width: 500px;
    height: 5rem;
}
.Complaint-form-control .select,
.Complaint-form-control .select option {
    display: block;
    border: 1px solid #52525d;
    width: 90% !important;
    overflow: hidden;
    height: 3.5rem;
    border-radius: 7px;
    text-indent: 1rem;

    background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
        no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 2rem !important;
    height: 3.6rem;
    font-size: 1.3rem;
    line-height: 41px;
    /* text-indent: 3.5rem; */
}
.modal-spinner {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
}
.modal-content-spinner {
    display: grid;
    justify-items: center;
    gap: 2rem;
    opacity: 1;
    max-width: 700px;
    width: 100%;
    padding: 2rem 0;
    position: relative;
}
/* ----------search-transaction--------------- */
.Complaint-formwrapper .search-transaction {
    margin-left: 5rem !important;
    display: grid;
    grid-template-columns: 0.5fr 0.3fr !important;
    /* flex-direction: row !important; */
}
.Complaint-formwrapper .search-transaction button {
    background: #010080;
    color: white;
    border: none;
    align-self: center;
    margin-top: 2rem;
    padding: 1rem 4rem;
    border-radius: 40px;
    font-family: "Poppins";
    font-style: normal;
    width: fit-content;
    height: fit-content;
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 22px;
}
.search-transaction-wrapper {
    display: grid;
    gap: 3rem;
}
.search-transaction-wrapper .or {
    justify-self: center;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 22px;
    /* identical to box height, or 73% */

    letter-spacing: 0.02em;

    color: #30303c;
}
.search-transaction-form-two {
    margin-left: 5rem !important;
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr 1fr !important;
    align-items: center;
}
.search-transaction-form-two button {
    background: #010080;
    color: white;
    border: none;
    align-self: center;
    margin-top: 2rem;
    padding: 1rem 4rem;
    border-radius: 40px;
    font-family: "Poppins";
    font-style: normal;
    width: fit-content;
    height: fit-content;
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 22px;
}

@media screen and (max-width: 900px) {
    .search-transaction-form-two,
    .search-transaction {
        display: grid;
        grid-template-columns: 1fr !important;
    }
}
/* input[type="date"] {
    display: flex;
    flex-flow: row-reverse;
    padding: 8px;
    height: auto;
    text-indent: 2rem;
} */

.search-transaction-form-two
    .input[type="date"]::-webkit-calendar-picker-indicator {
    margin-right: 10px;
}
.biller-name {
    text-align: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: bold;
    font-size: 2.4rem;
    /* line-height: 40px; */
    letter-spacing: 0.01em;
    color: #30303c;
}
