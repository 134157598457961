.aboutus-items{
    width: 350px;
    height: 400px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
}


.aboutus-item-icons{
    margin-bottom: 20px;
}


.heading{
    font-family: Rubik;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.02em;
}

.aboutus-heading{
    margin-top: 40vh;
    width:600px

}


.section2-aboutus{
    z-index: 10;
}




.bg-image{
    position: absolute;
   height: 900px;
   width: 1000px;
   top:0;
   right: 0;
    /* z-index: -1; */
}


.main-image{
    position: absolute;
    bottom:0;
    height: 550px;
    right: 100px;
    /* width: 100%; */
    z-index: 1;
}

.circle-infront{
    position: absolute;
    bottom:150px;
    height: 70px;
    right: 150px;
    z-index:2;
}



.circle-behind{
    position: absolute;
    bottom:350px;
    height: 100px;
    right: 600px;
    z-index:0;
}


.pattern-behind{
    position: absolute;
    bottom:420px;
    height: 100px;
    right: 180px;
    z-index:0;
}



.card-title{
    font-family: Rubik;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 10px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #6865F0;
    margin: 20px 0;
}

.card-des{
    font-family: Rubik;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.04em;
    text-align: left;

}
