/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
 */
.dummy-success {
    display: grid;
    align-items: center;
    justify-items: center;
    height: 100vh;
}
.pay-bills-grp1 input::-webkit-outer-spin-button,
.pay-bills-grp1 input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.pay-bills-grp1 input {
    -moz-appearance: textfield;
}
.Pg_charges {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 1rem;
}
.Pg_charges h3 {
    font-size: 1rem;
    color: red;
}
.all_charges {
    text-transform: uppercase;
}
